<template>
  <div class="devicontent">
    <div style="display: flex">
      <Headbuttom></Headbuttom>

      <div class="dei_rowp" slot="headerButtom">
        <el-button type="primary" class="but" size="small" @click="handAdddevi">
          <i class="el-icon-plus"></i>
          {{ $i18n.t("navLeft.add") }}
        </el-button>
        <div @click="handFromshow">
          <el-input
            size="small"
            class="dev_dowm"
            placeholder=""
            suffix-icon="el-icon-arrow-down"
            :readonly="true"
          >
          </el-input>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
    >
      <div class="frominput" v-if="flag">
        <el-form
          :inline="true"
          size="small"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item :label="$t('devtable.sbmc')">
            <el-input
              v-model="formInline.name"
              :placeholder="$t('devtable.sbmc')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('devtable.sbxlh')">
            <el-input
              v-model="formInline.serial_number"
              :placeholder="$t('devtable.sbxlh')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('devtable.sbzt')">
            <el-select v-model="formInline.device_state" placeholder="">
              <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
              <el-option :label="$t('devtable.lx')" value="0"></el-option>
              <el-option :label="$t('devtable.zx')" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('devtable.kmms')">
            <el-select v-model="formInline.lock_mode" placeholder="">
              <el-option :label="$t('devtable.wxz')" value="-1"></el-option>
              <el-option :label="$t('devtable.ysms')" value="0"></el-option>
              <el-option :label="$t('devtable.ckms')" value="1"></el-option>
              <el-option :label="$t('devtable.cbms')" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('devtable.sfzcj')">
            <el-select v-model="formInline.is_registe" placeholder="">
              <el-option :label="$t('devtable.f')" value="0"></el-option>
              <el-option :label="$t('devtable.s')" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">{{
              $t("devtable.cx")
            }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </transition>
    <Tables
      @handlpages="handlpages"
      @sortChange="sortChange"
      @handlerow="handlerow"
      @handleSizeChange="handleSizeChange"
      :tableData="tableData"
      :tableLabel="tableLabel"
      :operation="operation"
      :flags="flags"
      :configs="configs"
    ></Tables>
    <Deviadd
      @addDeviceSuccess="addDeviceSuccess"
      ref="deviadd"
      :titlename="titlename"
    ></Deviadd>
    <Maintainfrom @ ref="maintainfrom"></Maintainfrom>
    <Namefrom @handialogname="handialogname" ref="namefrom"></Namefrom>
  </div>
</template>
<script>
import Tables from "@/components/table";
import Deviadd from "./components/deviadd";
import Maintainfrom from "./components/maintainfrom";
import methodlist from "../../utils/methods";
import Namefrom from "./components/namefrom";
import { mapState } from "vuex";
import {
  openDoor,
  clearDevice,
  updateDevice,
  resetDevice,
} from "../../mqtt/mqtt";

export default {
  components: {
    Tables,
    Deviadd,
    Maintainfrom,
    Namefrom,
  },
  data() {
    return {
      flag: false,
      order_by: 0,
      tableData: [],

      flags: {
        show: false, // 多选是否显示
        ishow: false, // 序号是否显示
      },
      // 分页数据
      configs: {
        pagesize: 10,
        total: 200,
        loading: false, // 加载状态
      },
      // operation: ,

      titlename: "",

      formInline: {
        name: "",
        serial_number: "",
        device_state: "",
        lock_mode: "",
        factory: "",
        is_registe:''
      },
      UserInfo: "",
      lang: "",
      width: 120,
    };
  },
  computed: {
    ...mapState({
      langvalue: (state) => state.lang.langvalue,
    }),
    tableLabel: function () {
      return [
        {
          prop: "id",
          label: this.$t("devtable.sb"),
          align: "center",
          width: this.width,
        },
        {
          prop: "name",
          label: this.$t("devtable.azwz"),
          align: "center",
          width: this.width,
        },
        {
          prop: "unlock_state_hint",
          label: this.$t("devtable.kmzt"),
          align: "center",
          width: this.width,
        },
        {
          prop: "lock_mode_hint",
          label: this.$t("devtable.kmms"),
          align: "center",
          width: 140,
        },
        {
          prop: "serial_number",
          label: this.$t("devtable.sbxlh"),
          align: "center",
          width: this.width,
          sortable: true,
        },
        {
          prop: "device_state_hint",
          label: this.$t("devtable.zxzt"),
          align: "center",
          width: this.width,
        },
        {
          prop: "support_face_hint",
          label: this.$t("devtable.sblx"),
          align: "center",
          width: 150,
        },
        {
          prop: "is_registe_hint",
          label: this.$t("devtable.zcj"),
          align: "center",
          width: 130,
        },
        // {
        //   prop: 'province3',
        //   label: '设备名称',
        //   align: 'center'
        // }
      ];
    },
    operation: function () {
      return {
        width: 680,
        data: [
          {
            name: this.$t("devtable.qc"),
            type: "danger",
          },
          {
            name: this.$t("devtable.gx"),
            type: "primary",
          },
          {
            name: this.$t("devtable.km"),
            type: "primary",
          },
          {
            name: this.$t("devtable.wh"),
            type: "warning",
          },
			{
			  name: this.$t("devtable.llk"),
			  type: "info",
			},
          {
            name: this.$t("devtable.xg"),
            type: "info",
          },
          {
            name: this.$t("devtable.cq"),
            type: "warning",
          },
          {
            name: this.$t("tablename.sc"),
            type: "danger",
          },
        ],
      };
    },
    most: function () {
      return {
        ysms: this.$t("devtable.ysms"),
        ckms: this.$t("devtable.ckms"),
        frlj: this.$t("devtable.frlj"),
        rlj: this.$t("devtable.rlj"),
        zx: this.$t("devtable.zx"),
        lx: this.$t("devtable.lx"),
        s: this.$t("devtable.s"),
        f: this.$t("devtable.f"),
        cbms: this.$t("devtable.cbms"),
        j: this.$t("devtable.j"),
        c: this.$t("devtable.c"),
      };
    },
  },
  watch: {
    langvalue: function (o, n) {
      if (o != n) {
        this.getDeviceList(1);
      }
    },
  },

  mounted() {
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.getDeviceList(1);
  },
  methods: {
    handAdddevi() {
      this.$refs.deviadd.form.serial_number=""
      this.$refs.deviadd.form.device_face=""
      this.$refs.deviadd.form.name=""
      this.$refs.deviadd.add_edit_flag = 0;
      this.$refs.deviadd.mineStatus = ""
      this.$refs.deviadd.form.dept_id = ""
      this.titlename = this.$t("devtable.xzsb");
      
      // this.$refs.deviadd.getEditData(null,0);
      this.$refs.deviadd.dialogFormVisible = true;
    },
    // 查询
    onSubmit() {
      console.log("submit!");
      this.getDeviceList(1);
    },
    // 显示隐藏 查询主体
    handFromshow() {
      this.flag = !this.flag;
    },

    // 点击操作里面的按钮
    handlerow(v, name) {
      if (name == "清除" || name == "Eliminate") {
        clearDevice(v.serial, v.password);
      } else if (name == "更新" || name == "Update") {
        updateDevice(v.serial, v.password);
      } else if (name == "开门" || name == "Open") {
        openDoor(v.serial, v.password);
      } else if (name == "维护" || name == "Maintain") {
        this.mainatain(v);
      } else if (name == "修改" || name == "Modify") {
        this.titlename = this.$t("devtable.xgsbt");
        this.handlModify(v, name);
      } else if (name == "重启" || name == "Restart") {
        resetDevice(v.serial, v.password);
      } else if (name == "删除" || name == "Delete") {
        this.hanelDelete(v);
      }else if(name=='流量卡'||name=='Flow card'){
		  this.Flowcard(v)
	  }
    },
	// 流量卡操作
	Flowcard(v){
		console.log(v.id);
		let data = {
			method:'co.flow.card.bind',
			device_id:v.id,
			agent_id:this.UserInfo.agent_id
		}
		this.$serve(data).then(res=>{
			if(res.data.code==0){
				this.$message({
				  type: "success",
				  message: this.$t("devtable.bdcg"),
				});
			}else if(res.data.code==-3){
				this.$message({
				  type: "error",
				  message: this.$t("devtable.zbdllk"),
				});
			}else{
				this.$message({
				  type: "error",
				  message: this.$t("devtable.bdsb"),
				});
			}
		})
	},
    // 维护操作
    mainatain(devInfo) {
      this.$refs.maintainfrom.dialogFormVisible = true;
      this.$refs.maintainfrom.transferCurrDeviceInfo(devInfo);
	  this.$refs.maintainfrom.getSettings(devInfo.id);
    },
    // 修改操作
    handlModify(v, name) {
      this.$refs.deviadd.getEditData(v, 1);
      // this.titlename='修改设备'
      this.$refs.deviadd.dialogFormVisible = true;
    },
    // 排序
    sortChange(v) {
      if (v.order == "descending") {
        this.order_by = 1;
        this.getDeviceList();
      }
      if (v.order == "ascending") {
        this.order_by = 0;
        this.getDeviceList();
      }
    },

    //打开删除界面
    hanelDelete(data) {
      console.log(data);
      this.deldata = data;
      this.$refs.namefrom.dialogname = true;
    },
    //删除关闭事件
    handialogname(data) {
      if (data.name_input != this.UserInfo.user_name_save) {
        this.$message({
          type: "warning",
          message: this.$t("devtable.yhmsryw"),
        });
        return false;
      }

      if (data.pass_input != this.UserInfo.user_pass_save) {
        this.$message({
          type: "warning",
          message: this.$t("devtable.yhmmsryw"),
        });
        return false;
      }
      const params = {
        method: methodlist.ee_device_del,
        name: data.name_input,
        password: this.$md5(data.pass_input),
        id: this.deldata.id,
        mqtt_login_id: this.UserInfo.mqtt_login_id,
      };
      let datas = {
        method:'att.device.delete',
        agent_id:this.UserInfo.agent_id,
        device_id:this.deldata.id,
      }
      this.$serve(params).then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  message: this.$t("devtable.sccg"),
                  center: true,
                  type: "success",
                  duration: 2000,
                  offset: 100,
                });
                this.$refs.namefrom.dialogname = false;
                this.getDeviceList();
                this.$serve(datas).then((rest)=>{
                   console.log(`删除考勤记录`,res)
                })
              } else {
                //删除失败
                if (res.data.code == -8) {
                  this.$message({
                    message: this.$t("devtable.scsbmyqx"),
                    type: "warning",
                  });
                } else {
                  this.$message({
                    message: this.$t("devtable.scsb"),
                    type: "warning",
                  });
                }
              }
            });
      
      
    },
    // 分页
    handlpages(v) {
      console.log(v);
      this.getDeviceList(v);
    },
    handleSizeChange(val){
      this.getDeviceList(1,val);
    },
    //获取设备列表
    getDeviceList(pageIndex,val) {
      const params = {
        method: "co.device.list",
        name: this.formInline.name,
        serial_number: this.formInline.serial_number,
        device_state:
          this.formInline.device_state.length == 0
            ? "-1"
            : this.formInline.device_state,
        lock_mode:
          this.formInline.lock_mode.length == 0
            ? "-1"
            : this.formInline.lock_mode,
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
        page_index: pageIndex ? pageIndex : 1,
        page_size:val?val: this.configs.pagesize,
        order_by: this.order_by,
        is_registe:this.formInline.is_registe?this.formInline.is_registe:""
      };
      this.$serve(params).then((res) => {
        this.tableData = res.data.data;
        this.configs.total = res.data.count;
        this.tableData.forEach((el) => {
          if (el.unlock_state == 0) {
            el.unlock_state_hint = {
              state: true,
              type: "primary",
              name: this.most.j,
            };
          } else {
            el.unlock_state_hint = {
              state: true,
              type: "primary",
              name: this.most.c,
            };
          }
          if (el.lock_mode == 0)
            el.lock_mode_hint = { state: true, name: this.most.ysms };
          else if (el.lock_mode == 1)
            el.lock_mode_hint = { state: true, name: this.most.ckms };
          else el.lock_mode_hint = { state: true, name: this.most.cbms };

          if (el.support_face == 0)
            el.support_face_hint = { state: true, name: this.most.frlj };
          else el.support_face_hint = { state: true, name: this.most.rlj };

          if (el.device_state == 1) {
            el.device_state_hint = {
              state: true,
              type: "success",
              name: this.most.zx,
            };
          } else {
            el.device_state_hint = {
              state: true,
              type: "warning",
              name: this.most.lx,
            };
          }
          if (el.is_registe == 1) {
            el.is_registe_hint = {
              state: true,
              type: "success",
              name: this.most.s,
            };
          } else {
            el.is_registe_hint = {
              state: true,
              type: "warning",
              name: this.most.f,
            };
          }
        });
      });
    },
    addDeviceSuccess() {
      this.getDeviceList(1);
    },
  },
};
</script>
<style lang="less" scoped>
.devicontent {
  padding: 20px;
  background-color: #fff;
  .dei_rowp {
    display: flex;
    width: 30%;
    .dev_dowm {
      width: 200px;
    }
    .but {
      margin-left: 20px;
      margin-right: 30px;
    }
  }
  .frominput {
    margin-top: 25px;
    margin-left: 20px;
  }
}
.el-dialog__wrapper .el-dialog /deep/ .el-dialog__body {
  padding: 0px 20px 30px 20px !important;
}
.el-dialog__wrapper .el-dialog /deep/ .el-dialog__body .el-form {
  margin-top: 0;
}
</style>
